import { NavLink } from "react-router-dom";

import { useState } from "react";
// import { useRouter } from "next/router";

// import NavLink from "next/Navlink";
// import Image from "next/image";

import Logo from "../../images/logo/LOGO.webp"

import { MenuIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/outline";

const Navbar = () => {
    
    // const router = useRouter();

    const [isNavOpen, setIsNavOpen] = useState(false);

    return ( 
        <>
            <nav className="navbar">
                <div className="nav flex flex-row items-center justify-between">
                    {/* Logo */}
                    <div className="logo">
                        {/* <Image src={logo} alt="Logo" width={80} height={20}/> */}
                        <img src={Logo} className="" alt="" />
                        {/* <h2 className=" font-medium ">B&L Reinigungen</h2> */}
                    </div>
                    {/* Menu */}
                    <div className="menu hidden md:flex space-x-6">
                            <NavLink to="/" className="font-medium hover:text-sky-500 text-gray-700">Home</NavLink>
                            <NavLink to="/services" className="font-medium hover:text-sky-500 text-gray-700" >Dienstleistungen</NavLink>
                            <NavLink to="/cnc" className="font-medium hover:text-sky-500 text-gray-700" >CNC-Maschinenreinigung</NavLink>
                            <NavLink to="/about" className="font-medium hover:text-sky-500 text-gray-700">Über uns</NavLink>
                            <NavLink to="/contact" className="font-medium hover:text-sky-500 text-gray-700">Kontakt</NavLink>
                    </div>
                    <button 
                        id="menu-btn" 
                        className="hamburger"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        <div>
                            {isNavOpen ? <XIcon/> : <MenuIcon/>}
                        </div>
                    </button>
                </div>
                <div className=" md:hidden">
                    <div 
                        id="menu" 
                        className={isNavOpen ? "menu-shown" : "menu-hidden"}
                    >
                            <NavLink 
                                to="/"
                                onClick={() => setIsNavOpen(false)}
                                className={`font-medium hover:text-sky-500 `}
                            >
                                Home
                            </NavLink>
                            <NavLink 
                                to="/services"
                                onClick={() => setIsNavOpen(false)}
                                className={`font-medium hover:text-sky-500 `}
                            >
                                Dienstleistungen
                            </NavLink>
                            <NavLink 
                                to="/cnc"
                                onClick={() => setIsNavOpen(false)}
                                className={`font-medium hover:text-sky-500 `}
                            >
                                CNC-Maschinenreinigung
                            </NavLink>
                            <NavLink 
                                to="/about"
                                onClick={() => setIsNavOpen(false)}
                                className={`font-medium hover:text-sky-500`}    
                            >
                                Über uns
                            </NavLink>
                            <NavLink 
                                to="/contact"
                                onClick={() => setIsNavOpen(false)}
                                className={`font-medium hover:text-sky-500 `}
                            >
                                Kontakt
                            </NavLink>
                    </div>
                </div>
            </nav>
        </>
    );
}
 
export default Navbar;