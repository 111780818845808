import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

import Service from "../components/services/Service";
import Form from "../components/contact-form/Form";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import { CheckCircleIcon } from '@heroicons/react/outline'

import WindowIcon from "../images/icons/window.png";
import StairsIcon from "../images/icons/staircase.png";
import ConstructionIcon from "../images/icons/hook.png";
import FactoryIcon from "../images/icons/factory.png";
import BuildingIcon from "../images/icons/building.png";
import HomeIcon from "../images/icons/home.png";
import OfficeIcon from "../images/icons/office-building.png";
import HospitalIcon from "../images/icons/hospital.png";
import GymIcon from "../images/icons/dumbbell.png";

import img1 from "../images/img1.webp";
import img2 from "../images/img2.webp";
import img3 from "../images/img3.webp";
import img4 from "../images/img4.webp";
import img5 from "../images/img5.webp";

const Services = () => {
    return ( 
        <>
            <Navbar/>

            <div className="flex justify-center">
                <Swiper
                    height={750}
                    modules={[Autoplay, Pagination]}
                    autoplay={{delay: 4000}}
                    pagination={{clickable: true}}
                    loop={true}
                    slidesPerView={1}
                >
                    <SwiperSlide>
                        <img src={img1} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img2} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img3} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img4} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img5} className="w-screen" alt=""/>
                    </SwiperSlide>
                </Swiper>
                <div className="header z-10 hidden absolute w-auto md:flex flex-col mx-auto justify-center items-center drop-shadow-2xl">
                    <h2 className="header-title drop-shadow-2xl">B&L Reinigungen GmbH</h2>
                    <h4 className="header-txt drop-shadow-2xl">Wählen Sie das passende für Ihre Bedürfnisse aus.</h4>
                </div>
            </div>

            <div className="flex flex-col items-center">
                <h2 className="text-2xl font-medium text-gray-800 text-center m-5 p-2">
                Wir bieten alle Arten von Reinigungsdiensten <br/>und Hauswartungen an.
                </h2>

                <h2 className="text-xl font-medium text-gray-800 m-2 p-2">
                Geschäftsdienstleistungen
                </h2>
                <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    <Service
                        icon={<img src={HospitalIcon} alt=""/>}
                        title={"Medizinisch"}
                    />
                    <Service
                        icon={<img src={OfficeIcon} alt=""/>}
                        title={"Bürogebäude"}
                    />                                       
                    <Service
                        icon={<img src={ConstructionIcon} alt=""/>}
                        title={"Bau-und Endreinigung"}
                    />
                        
                    <Service
                        icon={<img src={BuildingIcon} alt=""/>}
                        title={"Gebäudereinigungen"}
                    />                        
                    <Service
                        icon={<img src={FactoryIcon} alt=""/>}
                        title={"Industriereinigungen"}
                    />                        
                    <Service
                        icon={<img src={GymIcon} alt=""/>}
                        title={"Fitnessstudio"}
                    />     
                    <Service
                        icon={<img src={HomeIcon} alt="" />}
                        title={"Hauswartungen"}
                    />                        
                    <Service
                        icon={<img src={StairsIcon} alt="" />}
                        title={"Privathaushalt und Treppenhäuser"}
                    />
                    <Service
                        icon={<img src={WindowIcon} alt=""/>}
                        title={"Fenster Reinigung"}
                    />                   
                </div>

                {/* <h2 className="text-xl font-medium text-gray-800 m-2 p-2">
                Private Dienstleistungen
                </h2>
                <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    <Service
                        icon={<img src={HomeIcon} alt="" />}
                        title={"Hauswartungen"}
                    />                        
                    <Service
                        icon={<img src={StairsIcon} alt="" />}
                        title={"Privathaushalt und Treppenhäuser"}
                    />
                    <Service
                        icon={<img src={WindowIcon} alt=""/>}
                        title={"Fenster Reinigung"}
                    />
                </div> */}

                <div data-aos="fade-up" className="">
                    <div className="py-2 grid grid-cols-1 md:grid-cols-2 mx-auto justify-items-center ">
                        <div className="list-one my-2 px-16 md:border-r-2">
                            <h2 className="text-xl font-medium text-gray-800 my-2 py-1">Wir verwenden die <br /> besten produkte</h2>
                            <ul className="my-1 p-1">
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Mikrofasertücher</li>
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Industrielle Vacuums</li>
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Desinfektionsmittel</li>
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Flache Mops</li>
                            </ul>
                        </div>
                        <div className="list-two my-2 px-16">
                            <h2 className="text-xl h-16 flex items-center font-medium text-gray-800 my-2 py-1">Wir pflegen extra</h2>
                            <ul className="my-1 p-1">
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Fenster</li>
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Harte Böden</li>
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Teppiche</li>
                              <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Stoffe</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="m-2 p-2">
                    <div className="flex justify-center">
                        <h2 className="text-2xl text-center font-medium text-gray-800 m-2 p-2">Möchten Sie mehr über unsere Dienstleistungen erfahren? <br /> Wenden Sie sich über das Formular an uns, und wir melden uns bei Ihnen!</h2>
                    </div>
                    <Form/>
                </div>
            </div>

            <Footer/>
        </>
     );
}
 
export default Services;