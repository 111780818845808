import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

import { CheckCircleIcon } from "@heroicons/react/outline";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import img1 from "../images/img1.webp";
import img2 from "../images/img2.webp";
import img3 from "../images/img3.webp";
import img4 from "../images/img4.webp";
import img5 from "../images/img5.webp";
import img8 from "../images/image2.png";
// import img6 from "../images/img6.webp";
import img7 from "../images/image3.jpg";
import img9 from "../images/image1.jpg";
import img10 from "../images/image4.jpg";

const CNC = () => {
    return ( 
        <>
            <Navbar/>

            <div className='bg'> 
            <div className="flex justify-center">
                <Swiper
                    height={750}
                    modules={[Autoplay, Pagination]}
                    autoplay={{delay: 4000}}
                    pagination={{clickable: true}}
                    loop={true}
                    slidesPerView={1}
                >
                    <SwiperSlide>
                        <img src={img2} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img1} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img3} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img4} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img5} className="w-screen" alt=""/>
                    </SwiperSlide>
                </Swiper>
                <div className="header z-10 hidden absolute w-auto md:flex flex-col mx-auto justify-center items-center drop-shadow-2xl">
                    <h2 className="header-title drop-shadow-2xl">B&L Reinigungen GmbH</h2>
                    <h4 className="header-txt drop-shadow-2xl">Erfahren Sie mehr über uns!</h4>
                </div>
            </div>

            <div data-aos="fade-up" className="flex flex-col md:flex-row justify-center items-center p-5">
                <div className="w-96 md:w-[50rem] flex flex-col items-start p-2 m-2">
                    <h2 className='text-2xl font-medium text-gray-800 m-2 p-1'>CNC-Maschinenreinigung - Polymechanik trifft auf Reinigung</h2>
                    <p className='m-2 p-1'>
                    Die B&L Reinigungen GmbH kombiniert langjährige Erfahrungen in der Polymechanik mit der Fachkompetenz aus der Reinigung. Gemeinsam mit unseren Partnern kümmern wir uns rund um Ihre CNC-Maschinen. Gemeinsam etablieren wir ein schonendes Verfahren, um Ihre CNC-Maschinen zu reinigen. Dabei erhöhen wir ihre Verfügbarkeit, erhöhen Ihre Lebensdauer und reduzieren ihren Verbrauch an Emulsion.
                    </p>
                </div>
            </div>

            <div data-aos="fade-up" className="flex flex-col md:flex-row justify-center items-center p-5">
                <div data-aos="zoom-in" className='m-2 p-2'>
                    <img src={img7} alt="" width={350} className=" rounded-3xl" />
                </div>
                <div className="w-96 md:w-[50rem] flex flex-col items-start p-2 m-2">
                    <h2 className='text-2xl font-medium text-gray-800 m-2 p-1'>Maschinenreinigung</h2>
                    <p className='m-2 p-1'>
                    Für die Reinigung verwenden wir Ihre maschineneigene Emulsion. Diese können wir bis auf rund 95°C erwärmen, um fettige und ölige Rückstände zu lösen. Im Niederdruck bis max. 14 bar entfernen wir kontrolliert und schonend die Verschmutzungen ohne Chemie. Abschliessend reinigen wir Ihrer CNC-Maschinen aussenherum. Die Werkzeuge können in der Aufnahme belassen werden. Mit einer regelmässige Maschinenreinigungen erhöhen Sie deren Verfügbarkeit und Lebensdauer. Unsere Arbeiten führen wir auf Ihren Betrieb abgestimmt durch, damit Ihre Mitarbeitenden möglichst störungsfrei weiterarbeiten können.
                    </p>
                </div>
            </div>

            <div data-aos="fade-up" className="flex flex-col-reverse md:flex-row justify-center items-center p-5">
                <div className="w-96 md:w-[50rem] flex flex-col items-start p-2 m-2">
                    <h2 className='text-2xl font-medium text-gray-800 m-2 p-1'>Emulsionsreinigung</h2>
                    <p className='m-2 p-1'>
                    Wir saugen die Emulsion aus dem Tank, um sie in einen Speicherbehälter zwischenzulagern. Während dem Saugprozess können wir Feststoffe wie Späne bis auf 5 µm herausfiltern. Den abgesetzten Ölschlamm entfernen wir ebenfalls und entsorgen ihn fachgerecht. So können wir einerseits den weiter steigenden Ölpreisen entgegenwirken und sorgen anderseits für unsere Umwelt mit einer kleineren Entsorgungsmenge an Emulsion. 
                    </p>
                </div>
                <div data-aos="zoom-in" className='m-2 p-2'>
                    <img src={img8} alt="" width={500} height={300} className="rounded-3xl" />
                </div>
            </div>

{/* flex flex-col md:flex-row justify-center items-center p-5 */}
            <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-2 p-5">
                <div data-aos="zoom-in" className='m-2 p-2 flex justify-center md:justify-end'>
                    <img src={img9} alt="" width={500} className=" rounded-3xl" />
                </div>
                <div className="list-one flex justify-center items-center">
                  <ul>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Höhere Verfügbarkeit</li>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Längere Lebensdauer Ihrer Maschinen</li>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Nachhaltiger Umgang mit der Emulsion</li>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Sauberer Arbeitsplatz</li>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Fokus auf die Produktion</li>
                  </ul>
                </div>
            </div>
            
            <div data-aos="fade-up" className="grid grid-cols-1 p-5">
                <div data-aos="zoom-in" className='m-2 p-2 flex justify-center '>
                    <img src={img10} alt="" width={700} className=" rounded-3xl" />
                </div>
            </div>
        </div>

            <Footer/>
        </>
     );
}
 
export default CNC;