import { useState, forwardRef } from "react";

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

// import { LocationMarkerIcon, PhoneIcon, MailIcon } from '@heroicons/react/outline';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

// Component Itself

const Form = () => {

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => { 
    setOpen(false);
  }

  return ( 
    <>
      {/* Contact Form */}
      
      <form >
        <div className="contact-form drop-shadow flex flex-col justify-center items-center m-2 p-2">
          <div className="contact-fullname w-64 sm:w-[30rem] lg:w-[35rem]">
            <span className="uppercase text-xs text-gray-500">Name</span>
            <input className="w-full h-10 bg-gray-100 text-gray-900 mt-1 p-3 rounded-lg border-b-2 border-sky-500 focus:outline-none" type="text" placeholder=""/>
          </div>
          <div className="contact-email w-64 sm:w-[30rem] lg:w-[35rem]">
            <span className="uppercase text-xs text-gray-500">E-Mail</span>
            <input className="w-full h-10 bg-gray-100 text-gray-900 mt-1 p-3 rounded-lg border-b-2 border-sky-500 focus:outline-none" type="text" placeholder=""/>
          </div>
          <div className="contact-message w-64 sm:w-[30rem] lg:w-[35rem]">
            <span className="uppercase text-xs text-gray-500 font-medium">Nachricht</span>
            <textarea className="w-full h-44 bg-gray-100 text-gray-900 mt-1 p-3 rounded-lg border-b-2 border-sky-500 focus:outline-none" type="text" placeholder=""/>
          </div>
          <button
            onClick={handleClick} 
            className='bg-sky-500 text-white font-medium py-2 px-5 rounded-full mt-2'
          >
              Senden
          </button>
        </div>        
      </form>

      <Snackbar 
        open={open} 
        autoHideDuration={5000}  
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose} 
          severity="success" 
          sx={{width: '100%'}}
        >
          Nachricht wurde gesendet!
        </Alert>
      </Snackbar>
    </>
  );
}
 
export default Form;