import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";

import ServicesSlider from "../components/services/ServicesSlider";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";

// import { CheckCircleIcon } from "@heroicons/react/outline";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup, faTruckFast, faBroom } from "@fortawesome/free-solid-svg-icons";

import HeaderImage from "../images/img2.webp";

const Home = () => {
    return ( 
        <>
            <Navbar/>

            <div className="wrapper flex flex-col">
        
        {/* Header */}

        <div className="flex flex-col justify-center items-center">
          <img src={HeaderImage} alt="" />
            <div className="header hidden absolute w-auto md:flex flex-col justify-center items-center drop-shadow-2xl">
              <h2 className="header-title">B&L Reinigungen GmbH</h2>
              <h4 className="header-txt drop-shadow-2xl">Wir schaffen ein Arbeitsumfeld und Zuhause in dem man sich wohl fühlt.</h4>
              <button className="header-btn drop-shadow-2xl">
                <a href="/contact">Kostenloser Besichtigungstermin Ihres Objekts buchen</a>
              </button>
            </div>
        </div>

        {/* Home */}

        <div id="Home" className="home">
          <div className="breif  mx-auto p-5 m-5">
            <h2 className="text-2xl font-medium text-gray-800">Reinigungsfirma Zürich - B&L Reinigungen GmbH</h2>
            <p className="md:w-[45rem] my-2 py-2 text-gray-700">
              Sauberkeit spielt eine wichtige Rolle im Leben. Viele Hauseigentümer und Unternehmen haben aber weder die Ressourcen noch die Lust dazu, sich selbst um die Reinigung und Hauswartung zu kümmern. Und hier kommt die B&L Reinigungen GmbH ins Spiel.
              <br />
              <br />
              Wir bei B&L Reinigungen sind der Meinung, dass ein sauberes Zuhause glücklich und zufriedener macht und dass ein sauberes und aufgeräumtes Arbeitsumfeld sich in der Produktivität und Performance wiederspeigelt.  Deswegen erachten wir unsere Reinigungsarbeiten und Hauswartungen als wichtig und führen diese jederzeit mit Freude und Hingabe aus.
              <br />
              <br />
              B&L Reinigungen legt grossen Wert auf gut ausgebildete und erfahrene MitarbeiterInnen und reinigt ausschliesslich mit hochwertigen Reinigungsmitteln und verwendet professionelle Reinigungsgeräte für Ihr Objekt. Qualität und Kundenzufriedenheit steht für B&L Reinigungen an oberster Stelle.
            </p>
            {/* <div className="">
              <h2 className="text-xl font-medium text-gray-800">Wir ermöglichen</h2>
              <div className="py-2 grid grid-cols-1 md:grid-cols-2">
                <div className="list-one">
                  <ul>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Erfahrene Anbieter</li>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Benutzerdefinierte Zeitpläne</li>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Erweiterte Produkte</li>
                  </ul>
                </div>
                <div className="list-two">
                  <ul>
                    <li className="text-gray-700 py-1 flex"><CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/>Zusätzliche Pflegehygiene</li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>

          {/* Services */}

          <div className="our-service flex flex-col items-center m-5 p-2 bg-white border-[1px] border-gray-100 w-screen">
            <h2 className="text-2xl font-medium text-gray-800 m-5 p-1">Was wir anbieten</h2>
            <ServicesSlider/>
              <button data-aos="fade-up" className="bg-sky-500 hover:bg-sky-400 text-white font-medium py-2 px-3 rounded-full m-5">
                <a href="/services">
                Mehr über Dienstleistungen
                </a> 
              </button>
          </div>
          
          <div className="flex flex-col items-center justify-center m-5 py-5">
            <h2 className="text-2xl font-medium text-gray-800 m-2 p-1">Warum uns wählen</h2>
            <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-3">
              <div className="flex xl:w-96">
                <div className="p-2 flex justify-center items-center">
                  <FontAwesomeIcon 
                    icon={faPeopleGroup} 
                    color="#0ea5e9" 
                    size="xl" 
                  />
                </div>
                <div className="flex flex-col p-2">
                  <h2 className="text-lg font-medium text-gray-700">Erfahrene Mitarbeiter</h2>
                  <p className="text-gray-600">
                  Professionelle und erfahrene Mitarbeiter stehen Ihnen jederzeit zur Verfügung.
                  </p>
                </div>
              </div>
              <div className="flex xl:w-96">
                <div className="p-2 flex justify-center items-center">
                  <FontAwesomeIcon 
                      icon={faTruckFast} 
                      color="#0ea5e9" 
                      size="xl" 
                  />
                </div>
                <div className="flex flex-col p-2">
                  <h2 className="text-lg font-medium text-gray-700">Schneller Service</h2>
                  <p className="text-gray-600">
                  Legen Sie Ihren Zeitplan fest und erhalten Sie jeden Tag eine regelmäßige und schnellere Reinigung.
                  </p>
                </div>
              </div>
              <div className="flex xl:w-96">
                <div className="p-2 flex justify-center items-center">
                  <FontAwesomeIcon 
                    icon={faBroom} 
                    color="#0ea5e9" 
                    size="xl" 
                  />
                </div>
                <div className="flex flex-col p-2">
                  <h2 className="text-lg font-medium text-gray-700">Beste Ausrüstung</h2>
                  <p className="text-gray-600">
                  Wir verwenden die beste und weltklasse Ausrüstung für den Reinigungsprozess.
                  </p>
                </div>
              </div>
            </div>
            <div className="my-5 py-3">
              <h2 className="text-xl font-medium text-gray-700 text-center m-2 p-2">Immer noch nicht überzeugt? <br />Hier sind die Erfahrungsberichte unserer Kunden</h2>
              <TestimonialSlider/>
            </div>
          </div>
        </div>

      </div>

            <Footer/>
        </>
     );
}
 
export default Home;