import Service from './Service';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/css';
import 'swiper/css/autoplay'

import WindowIcon from "../../images/icons/window.png";
import StairsIcon from "../../images/icons/staircase.png";
import ConstructionIcon from "../../images/icons/hook.png";
import FactoryIcon from "../../images/icons/factory.png";
import BuildingIcon from "../../images/icons/building.png";
import HomeIcon from "../../images/icons/home.png";
import OfficeIcon from "../../images/icons/office-building.png";
import HospitalIcon from "../../images/icons/hospital.png";
import GymIcon from "../../images/icons/dumbbell.png";

// import { HomeIcon, OfficeBuildingIcon } from '@heroicons/react/outline'

const Services = () => {
    return ( 
        <>
            <div data-aos="fade-up" id="Services" className="services">
                <div className="services-holder">
                    <Swiper
                        modules={[Autoplay]}
                        autoplay={{ delay:2500 }}
                        loop={true}
                        slidesPerView={3}
                        width={1000}
                    >
                        <SwiperSlide>
                            <Service 
                                icon={<img src={HomeIcon} alt=""/>}
                                title={"Hauswartungen"}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Service 
                                icon={<img src={StairsIcon} alt="" />}
                                title={"Privathaushalt und Treppenhäuser"}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Service 
                                icon={<img src={OfficeIcon} alt="" />}
                                title={"Büro-& Gewebereinigung"}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Service 
                                icon={<img src={WindowIcon} alt="" />}
                                title={"Fensterreinigung"}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Service 
                                icon={<img src={ConstructionIcon} alt="" />}
                                title={"Bau- & Endreinigung"}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Service 
                                icon={<img src={BuildingIcon} alt=""/>}
                                title={"Gebäudereinigung"}
                            />
                        </SwiperSlide>


                        <SwiperSlide>
                            <Service 
                                icon={<img src={FactoryIcon} alt="" />}
                                title={"Industriereinigung"}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Service 
                                icon={<img src={HospitalIcon} alt="" />}
                                title={"Medizinisch"}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Service 
                                icon={<img src={GymIcon} alt="" />}
                                title={"Fitnessstudio"}
                            />
                        </SwiperSlide>
                    </Swiper>  
                </div>
            </div>
        </>
    );
}
 
export default Services;