import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

import TestimonialSlider from '../components/testimonials/TestimonialSlider';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import img1 from "../images/img1.webp";
import img2 from "../images/img2.webp";
import img3 from "../images/img3.webp";
import img4 from "../images/img4.webp";
import img5 from "../images/img5.webp";
// import img6 from "../images/img6.webp";
import img7 from "../images/img7.webp";

const About = () => {
    return ( 
        <>
            <Navbar/>

            <div className='bg'> 
            <div className="flex justify-center">
                <Swiper
                    height={750}
                    modules={[Autoplay, Pagination]}
                    autoplay={{delay: 4000}}
                    pagination={{clickable: true}}
                    loop={true}
                    slidesPerView={1}
                >
                    <SwiperSlide>
                        <img src={img2} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img1} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img3} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img4} className="w-screen" alt=""/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img5} className="w-screen" alt=""/>
                    </SwiperSlide>
                </Swiper>
                <div className="header z-10 hidden absolute w-auto md:flex flex-col mx-auto justify-center items-center drop-shadow-2xl">
                    <h2 className="header-title drop-shadow-2xl">B&L Reinigungen GmbH</h2>
                    <h4 className="header-txt drop-shadow-2xl">Erfahren Sie mehr über uns!</h4>
                </div>
            </div>
                 
            {/* <div data-aos="fade-down" className='md:w-[45rem] m-5 p-5 mx-auto'>
                <ol className="relative border-l border-sky-500">                  
                    <li className="mb-10 ml-4">
                        <div className="absolute w-3 h-3 bg-sky-500 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400">2020</time>
                        <h3 className="text-lg font-semibold text-gray-900">The beginning</h3>
                        <p className="mb-4 text-base font-normal text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente nulla quia quas, sit sint, ea ad eaque perspiciatis reprehenderit quasi excepturi repellendus necessitatibus, voluptate sunt fuga laudantium ipsa alias ab.</p>
                    </li>
                    <li className="mb-10 ml-4">
                        <div className="absolute w-3 h-3 bg-sky-500 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400">2021</time>
                        <h3 className="text-lg font-semibold text-gray-900">How we evolved</h3>
                        <p className="text-base font-normal text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis consectetur atque vero iste doloribus impedit delectus ratione doloremque culpa, deserunt magnam veniam repellendus adipisci, rerum illum rem quidem, magni sint.</p>
                    </li>
                    <li className="ml-4">
                        <div className="absolute w-3 h-3 bg-sky-500 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400">2022</time>
                        <h3 className="text-lg font-semibold text-gray-900">Better than ever</h3>
                        <p className="text-base font-normal text-gray-500">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius culpa neque sapiente quam asperiores incidunt doloribus, aliquid, inventore fugiat eveniet, provident nostrum eum tenetur necessitatibus. Non sequi eius sed reprehenderit.</p>
                    </li>
                </ol>
            </div> */}

            <div data-aos="fade-up" className="flex flex-col md:flex-row justify-center items-center p-5">
                <div data-aos="zoom-in" className='m-2 p-2'>
                    <img src={img7} alt="" width={750} height={562.5} className=" rounded-3xl" />
                </div>
                <div className="w-96 md:w-[50rem] flex flex-col items-start p-2 m-2">
                    <h2 className='text-2xl font-medium text-gray-800 m-2 p-1'>Bis Sie zufrieden sind!</h2>
                    <p className='m-2 p-1'>
                    Eigentümer, Familien und Unternehmen vertrauen B&L Reinigungen weil sie wissen, dass wir immer unser Bestes geben und auch dem kleinsten Detail unsere Aufmerksamkeit schenken.
                    <br/> 
                    B&L Reinigungen legt grossen Wert auf gut ausgebildete und erfahrene MitarbeiterInnen und reinigt ausschliesslich mit hochwertigen Reinigungsmitteln und verwendet dabei professionelle Reinigungsgeräte für Ihr Objekt.
                    </p>
                </div>
            </div>

            <div data-aos="fade-up" className="flex flex-col-reverse md:flex-row justify-center items-center p-5">
                <div className="w-96 md:w-[50rem] flex flex-col items-start p-2 m-2">
                    <h2 className='text-2xl font-medium text-gray-800 m-2 p-1'>Lassen sie uns die arbeit tun!</h2>
                    <p className='m-2 p-1'>
                        Garantie auf Dienstleistungen
                        Unser Versprechen bedeutet, dass Sie beruhigt sein können. Wenn Sie mit unseren Dienstleistungen unzufrieden sind, können Sie sich mit uns in Verbindung setzen, und wir werden das Problem ohne zusätzliche Kosten lösen.
                        Wir kümmern uns mit zunehmender Sorgfalt um die Frage der Kundenzufriedenheit im Zusammenhang mit unseren Dienstleistungen.
                        Spezialisierte und personalisierte Reinigung nur für Sie
                        Wenn Sie einen Vertrag mit uns abschließen, können Sie sich sicher fühlen, denn wir werden mit Ihnen über Ihre speziellen Reinigungsbedürfnisse sprechen. Wir sind immer genau in unseren Dienstleistungen und alle Ihre Bedürfnisse werden bei der Reinigung berücksichtigt.
                        Qualität
                        Es gibt kein besseres Gefühl, als zu Ihnen nach Hause oder in Ihr Geschäft zu kommen und es sauber zu haben. Wenn B&L Reinigungen den Reinigungsdienst übernimmt, können Sie den Unterschied erkennen. Wir möchten, dass Sie stolz auf Ihr Zuhause oder Ihr Unternehmen sind, und wir sind zuversichtlich, dass wir alle Ihre Reinigungsanforderungen erfüllen können.
                    </p>
                </div>
                <div data-aos="zoom-in" className='m-2 p-2'>
                    <img src={img5} alt="" width={750} height={562.5} className="rounded-3xl" />
                </div>
            </div>
            
            <div className='flex flex-col justify-center items-center'>
                <div className="flex justify-center items-center">
                    <h2 className="text-2xl font-medium text-gray-700 m-2 p-2">Unsere Reputation ist makellos</h2>
                </div>
                <TestimonialSlider/>
            </div>
        </div>

            <Footer/>
        </>
     );
}
 
export default About;