import { useState } from "react";

import { CheckCircleIcon, LocationMarkerIcon, PhoneIcon, MailIcon } from "@heroicons/react/outline";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import Logo from "../../images/logo/LOGO.webp"

const Footer = () => {

    const [hover, setHover] = useState(false);
    const [instaHover, setInstaHover] = useState(false);

    return ( 
        <>
            <div className="footer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
                <div className="footer-description flex flex-col items-center m-auto p-10">
                    <div className="logo">
                        <img src={Logo} className="pb-2" alt="" />
                        {/* <h1 className="text-2xl font-medium text-gray-800 m-2 p-2">Logo</h1> */}
                    </div>
                    <p className="text-gray-700">
                    Es gibt kein besseres Gefühl, als in Ihr Haus oder Geschäft zu gehen und es sauber zu machen. Wenn B&L Reinigung den Reinigungsservice übernimmt, sieht man den Unterschied. Wir möchten, dass Sie stolz auf Ihr Zuhause oder Ihr Geschäft sind, und wir sind zuversichtlich, dass wir alle Ihre Reinigungsanforderungen erfüllen können.
                    </p>
                </div>

                {/* Services */}

                <div className="footer-services flex flex-col justify-center items-center m-2 p-5 ">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-medium text-gray-800 py-1 px-2">Was wir anbieten</h2>
                        <ul className="my-1 py-1">
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Umzugsreinigung
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Hauswartungen
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Privathaushalt und Treppenhäuser
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Bürogebäude
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Fenster Reinigung
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Bau-und Endreinigung
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Gebäudereinigungen
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <CheckCircleIcon className="w-6 p-0.5 stroke-sky-500"/> 
                                Industriereinigungen
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Info */}

                <div className="footer-info flex flex-col items-center m-2 p-5">
                    <div className="flex flex-col items-start">
                        <h2 className="text-xl font-medium text-gray-800 py-1 px-2">Kontaktinformationen</h2>
                        <ul className="my-1 py-1">
                            <li className="text-gray-700 flex p-1">
                                <LocationMarkerIcon className="w-6 p-0.5 stroke-sky-500"/>
                                Frohburgweg 1 8180 Bülach
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <PhoneIcon className="w-6 p-0.5 stroke-sky-500"/>
                                (+41) 079 598 58 03
                            </li>
                            <li className="text-gray-700 flex p-1">
                                <MailIcon className="w-6 p-0.5 stroke-sky-500"/>
                                info@bl-reinigungen.ch
                            </li>
                            {/* <li className="text-gray-700 flex p-1">
                                <ClockIcon className="w-6 p-0.5 stroke-sky-500"/>
                                Mon-Fri: 08:00AM - 06:30PM
                            </li> */}
                        </ul>
                    </div>
                    <button className="bg-sky-500 text-white px-3 pt-1 pb-2 mt-2 rounded-full">
                        <a href="/contact">
                            Kontaktiere uns
                        </a>
                    </button>
                </div>
            </div>

            {/* Copyright and Social Media */}

            <div className="footer-banner grid grid-cols-2 p-2 my-auto  bg-white">
                <div className="copyright text-gray-500 my-auto mx-20 m-1">
                    <p>&copy; 2022 B&L Reinigungen GmbH </p>
                </div>
                <div className="social-media flex justify-end my-auto mx-20 m-1 p-1">
                    <a 
                        href="https://www.facebook.com/BL.Reinigungen"
                        className="m-1"
                        onMouseOver={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <FontAwesomeIcon 
                            icon={faFacebook} 
                            style={hover ? {color: "#0ea5e9"} : {color: "gray"}} 
                            size="xl"
                        />
                    </a>
                    <a href="https://www.instagram.com/bl.reinigungen/"
                        className="m-1"
                        onMouseOver={() => setInstaHover(true)}
                        onMouseLeave={() => setInstaHover(false)}
                    >
                        <FontAwesomeIcon 
                            icon={faInstagram} 
                            style={instaHover ? {color: "#0ea5e9"} : {color: "gray"}} 
                            size="xl"
                        />
                    </a>
                </div>
            </div>
        </>
    );
}
 
export default Footer;