import Rating from '@mui/material/Rating';

const Testimonials = ({name, testimonial, rating}) => {
    return ( 
        <>
            <div className="flex flex-col justify-center items-center mx-auto w-72 md:w-96 h-52 m-2 p-5 bg-gray-50 rounded-xl shadow drop-shadow">
                <div className="m-1 pt-2">
                    <h2 className="text-xl font-medium ">{name}</h2>
                </div>
                <div className="m-1 pb-2  text-center">
                    <p>&quot;{testimonial}&quot;</p>
                </div>
                <div className="pb-2">
                    <Rating
                        value={rating}
                        readOnly
                    />
                </div>
            </div>
        </>
    );
}
 
export default Testimonials;