import Testimonial from './Testimonial';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

const TestimonialSlider = () => {
    return ( 
        <>
            <div data-aos="flip-up" className='mx-auto my-5 mr-5 md:mx-auto w-96 md:w-[25rem] lg:w-[50rem] xl:w-[75rem] justify-items-center'>
                <Swiper
                    modules={[Autoplay, Navigation]}
                    autoplay={{ delay:3000 }}
                    // navigation
                    loop={true}
                    slidesPerView={3}
                    width={1200}
                >
                    <SwiperSlide>
                        <Testimonial
                            name={"Gabriel"}
                            testimonial={"B&L Reinigungen sind die besten. Wir sind mit ihrer Arbeit zufrieden."}
                            rating={4}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Testimonial
                            name={"Matteo"}
                            testimonial={"B&L Reinigungen sind das Erstaunliche. Immer pünktlich und sie halten ihre Versprechen."}
                            rating={5}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Testimonial
                            name={"Elias"}
                            testimonial={"B&L Reinigungen, sie sind in unserem Haus immer willkommen. Sie machen einen guten Job und wir werden langfristig mit ihnen zusammenarbeiten."}
                            rating={5}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    );
}
 
export default TestimonialSlider;