const NotFound = () => {
    return ( 
        <>
            <h1 style={{
                color: "black",
                display: "block",
                fontSize: "2em",
                marginTop: "0.67em",
                marginBottom: "0.67em",
                marginLeft: "0",
                marginRight: "0",
                fontWeight: "bold",
                fontFamily: "initial",
            }}>404 Page Not Found</h1>
        </>
     );
}
 
export default NotFound;

// display: block; font-size: 2em; margin-top: 0.67em; margin-bottom: 0.67em; margin-left: 0; margin-right: 0; font-weight: bold;