const Service = ({icon, title, description}) => {
    return ( 
        <>
            <div className="service flex flex-col justify-center items-center m-5 p-5 hover:-translate-y-5 hover:ease-in delay-50 duration-200">
                <div className='p-1 w-16 stroke-sky-500'>
                    {icon}
                </div>
                <h2 className='p-2 text-lg font-medium text-gray-600'>
                    {title}
                </h2>
            </div>
        </>
    );
}
 
export default Service;