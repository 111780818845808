import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

import Form from "../components/contact-form/Form";

import { LocationMarkerIcon, PhoneIcon, MailIcon } from '@heroicons/react/outline';

const Contact = () => {
    return ( 
        <>
            <Navbar/>

            {/* Map */}

        <div className="">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2811.264458091284!2d8.537197360496691!3d47.524393091667264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479075d55393637b%3A0x79a306ff65f1ccaf!2sFrohburgweg%201%2C%208180%20B%C3%BClach%2C%20Switzerland!5e0!3m2!1sen!2s!4v1653921538800!5m2!1sen!2s"
            height="600"
            className="w-full"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        {/* Contact Info */}

        <div
          data-aos="fade-up"
          id="contact"
          className="contact-info mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-2 p-2"
        >
          <div className="contact-address flex flex-col items-center my-2 mx-5">
            <div className="info-title flex flex-row justify-center items-center">
              <LocationMarkerIcon className="w-7 stroke-sky-500 p-0.5" />
              <h2 className="font-medium text-lg text-gra">Adresse</h2>
            </div>
            <div className="info-content text-gray-700">
              <p>Frohburgweg 1 8180 Bülach</p>
            </div>
          </div>
          <div className="contact-phone flex flex-col items-center my-2 mx-5">
            <div className="info-title flex flex-row justify-center items-center">
              <PhoneIcon className="w-7 stroke-sky-500 p-0.5" />
              <h2 className="font-medium text-lg text-gra">Telefon</h2>
            </div>
            <div className="info-content text-gray-700">
              <p>(+41) 079 598 58 03</p>
            </div>
          </div>
          <div className="contact-smth flex flex-col items-center my-2 mx-5">
            <div className="info-title flex flex-row justify-center items-center">
              <MailIcon className="w-7 stroke-sky-500 p-0.5" />
              <h2 className="font-medium text-lg text-gra">Email</h2>
            </div>
            <div className="info-content text-gray-700">
              <p>info@bl-reinigungen.ch</p>
            </div>
          </div>
        </div>

        <div className="">
          <div className="flex justify-center">
            <h2 className="text-2xl font-medium text-gray-800 m-2 p-1">Nehmen Sie Kontakt mit uns auf!</h2>
          </div>
          <Form/>
        </div>

            <Footer/>
        </>
     );
}
 
export default Contact;